import { BsTelephoneFill } from "react-icons/bs"
import { FaPaperPlane } from "react-icons/fa"

function Footer() {
    return (
        <footer className="bg-[#1C1E29] py-[80px] scroll-mt-24 md:scroll-mt-0" id="contact">
            <div className="container_lg border-b-[3px] border-[#383A42] pb-16">
                <div className="grid md:grid-cols-[40%,60%]">
                    <div className="flex flex-col gap-10 md:gap-0 justify-between">
                        <div className="space-y-5">
                            <h3 className="titleClass text-soft-white  capitalize">
                                let's get talking!
                            </h3>
                            <p className="paraClass text-soft-white w-[80%]">
                                Ask away and we aim to respond to all our messages as quickly
                                as possible.
                            </p>
                        </div>
                        <div>
                            <div className="space-y-4">
                                <div className="flex gap-4 items-center">
                                    <BsTelephoneFill color="white" />
                                    <span className="font-semibold text-soft-white">
                                        702-796-5221
                                    </span>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <FaPaperPlane color="white" />
                                    <span className="font-semibold text-soft-white">
                                        george@gdgreenberglaw.com
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="flex flex-col gap-12 mt-16 md:first-letter:mt-0">
                        <div className="flex flex-col gap-12 md:flex-row">
                            <div className="flex flex-col gap-2 w-full relative">
                                <input
                                    className="peer bg-transparent border-b-[3px] border-[#383A42] outline-none text-soft-white font-semibold font-openSans placeholder-transparent"
                                    type="text"
                                    id="name"
                                    placeholder="NAME"
                                />
                                <label
                                    className="uppercase font-openSans font-semibold text-[#383A42] absolute -top-6 left-0 transition-all peer-placeholder-shown:top-0"
                                    htmlFor="name"
                                >
                                    name
                                </label>
                            </div>
                            <div className="flex flex-col gap-2 w-full relative">
                                <input
                                    className="peer bg-transparent border-b-[3px] border-[#383A42] outline-none text-soft-white font-semibold font-openSans placeholder-transparent"
                                    type="text"
                                    id="email"
                                    placeholder="EMAIL"
                                />
                                <label
                                    className="uppercase font-openSans font-semibold text-[#383A42] absolute -top-6 left-0 transition-all peer-placeholder-shown:top-0"
                                    htmlFor="email"
                                >
                                    email
                                </label>
                            </div>
                        </div>
                        <div className="relative">
                            <textarea
                                className="peer w-full bg-transparent border-b-[3px] border-[#383A42] outline-none text-soft-white font-semibold font-openSans resize-none placeholder-transparent"
                                id="textArea"
                                placeholder="TEXTAREA"
                            ></textarea>
                            <label
                                className="uppercase font-openSans font-semibold text-[#383A42] absolute -top-6 left-0 transition-all peer-placeholder-shown:top-0"
                                htmlFor="textArea"
                            >
                                message
                            </label>
                        </div>
                        <div>
                            <button className="btnClass px-10">submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <p className="capitalize text-soft-white font-semibold text-center translate-y-[40px] text-base md:text-xl">
                <span>&copy;</span>
                <span className="pl-4">
                    Loremipsum Inc 2022. All Rights Reserved.
                </span>
            </p>
        </footer>
    )
}

export default Footer
