import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import { useEffect } from "react";
function Header() {
  const [showSidebar, setShowSidebar] = useState(false);

  const closeSidebar = (e) =>
    e?.target?.id === "mobile_sidebar" && setShowSidebar(false);
  const closeSidebarForcely = () => setShowSidebar(false);
  useEffect(() => {
    document.body.style.overflow = showSidebar ? "hidden" : "auto";
  }, [showSidebar]);

  useEffect(() => {
    const toggleHeaderBg = (e) => {
      if (document.documentElement.scrollTop > window.innerHeight - 50) {
        document.querySelector("#top_header").style.background = "black";
      } else {
        document.querySelector("#top_header").style.background = "transparent";
      }
    };

    window.addEventListener("scroll", toggleHeaderBg);

    return () => window.removeEventListener("scroll", toggleHeaderBg);
  }, []);

  return (
    <header
      className="fixed top-0 left-0 w-full z-50 duration-[0.4s]"
      id="top_header"
    >
      <div className="container_lg relative py-4 md:py-6 flex justify-between items-center ">
        {/* Logo Start */}
        <div className="shrink-0  ">
          <div className="flex text-soft-white font-semibold text-3xl items-center">
            <a href="/" className="w-[70px] block md:w-auto">
              <img src="/img/logo.png" alt="" />
            </a>
            <p className="pl-2">Attorney Bitcoin</p>
          </div>
        </div>
        {/* Logo End */}

        <div>
          {/* Nav Start */}
          <div
            onClick={closeSidebar}
            id="mobile_sidebar"
            className={`fixed pointer-events-none top-0 left-0 w-full h-screen bg-black/50 z-50 md:static md:w-auto md:h-auto md:bg-transparent opacity-0 duration-200 ${
              showSidebar ? "opacity-100 pointer-events-auto" : ""
            } md:opacity-100 md:pointer-events-auto font-openSans`}
          >
            <ul
              className={`gap-10 font-quick-sand font-bold text-black md:text-[#8A8A8A] bg-primary pt-5 md:pt-0 w-[80%] md:w-auto md:flex h-full md:h-auto md:bg-transparent -translate-x-full duration-200 ${
                showSidebar ? "translate-x-0" : ""
              } md:translate-x-0`}
            >
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#home"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#meet-attorney"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#news"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  News
                </a>
              </li>
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#guidence"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  Guidence
                </a>
              </li>
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#tutorial"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  Tutorial
                </a>
              </li>
              <li>
                <a
                  onClick={closeSidebarForcely}
                  href="#contact"
                  className="hover:text-white border-b-2 border-transparent hover:border-primary duration-100 px-6 py-2.5 block md:p-1"
                >
                  Contact
                </a>
              </li>
            </ul>
            <div
              className="text-white hover:text-white border-b-2 border-transparent hover:border-primary hover:scale-110 duration-100 md:hidden"
              onClick={() => setShowSidebar((prev) => !prev)}
            >
              <GiHamburgerMenu color="#fff" size={25} />
            </div>
          </div>
          {/* Nav End */}
          <div className="text-soft-white flex items-center gap-6 md:gap-8">
            {/* Hamburger Icon --Start-- */}
            <div
              className="hover:text-white md:hidden"
              onClick={() => setShowSidebar((prev) => !prev)}
            >
              {showSidebar ? (
                <IoMdClose size={28} className="relative z-50" />
              ) : (
                <GiHamburgerMenu size={25} />
              )}
            </div>
            {/* Hamburger Icon --End-- */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
