import VideoPlayer from "./VideoPlayer";
import { PopupButton } from "react-calendly";

function NewToBitcoinSection() {
  return (
    <section
      className="mb-14 md:mb-32 scroll-mt-32 md:scroll-mt-36"
      id="tutorial"
    >
      <div className="container_lg">
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-6 md:gap-14">
          <div>
            <VideoPlayer />
          </div>
          <div className="space-y-3 md:space-y-5">
            <h3 className="titleClass">New to Bitcoin?</h3>
            <p className="paraClass">
              I have provided a short video that will give you a quick overview
              into Bitcoin. Our team is on hand to answer any questions and if
              you're ready to start, give us a call!
            </p>
            <PopupButton
              className="btnClass !mt-6 md:mt-auto"
              url="https://calendly.com/attorneybitcoin"
              rootElement={document.getElementById("root")}
              text="Book A Call!"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewToBitcoinSection;
