import { useRef } from "react"
import { useState } from "react"
import { BsFillPlayFill } from "react-icons/bs"

function VideoPlayer() {
    const [isShowControls, setIsShowControls] = useState(false)
    const videoRef = useRef(null)

    const playVideoHandler = () => {
        videoRef.current.play()
        setIsShowControls(true)
    }

    return (
        <div className="relative overflow-hidden">
            {/* Overly --Start-- */}
            {!isShowControls && <div className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[105%] h-[105%] z-[5] flex items-center justify-center bg-black/70">
                <div className="w-16 h-16 rounded-full bg-primary flex items-center justify-center cursor-pointer hover:bg-primary-hover duration-150 text-white hover:scale-110" onClick={playVideoHandler}>
                    <BsFillPlayFill size={28} />
                </div>
            </div>}
            {/* Overly --End-- */}
            <video src="/bitcoin_video.mp4" controls={isShowControls} ref={videoRef}></video>
        </div>
    )
}

export default VideoPlayer
