import { PopupButton } from "react-calendly";

function MeetAttorneySection() {
  return (
    <section
      className="sectionPy md:mb-14 relative before:hidden md:before:block before:bg-soft-white before:absolute before:top-0 before:left-0 before:w-[70%] before:h-full before:z-[-1] scroll-mt-10"
      id="meet-attorney"
    >
      <div className="container_lg">
        {/* Grid Div */}
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-12 md:gap-[75px]">
          {/* Div 1 */}
          <div className="space-y-6">
            <h3 className="titleClass ">Meet the Attorney.</h3>
            <p className="paraClass">
              I have been an attorney for 30 years and have observed blockchain
              technology changing the world. However, crypto and its
              complexities demand the consumer be made aware of the challenges
              to this new frontier. Avoiding scams, reducing risk, and
              navigating governmental oversight are key issues. Attorney George
              Greenberg will help you to move forward with confidence.
            </p>
            {/* <p className="paraClass">
              The potential with Bitcoin is huge, if you tread carefully your
              ROI could be significant and I will get you safely started and
              guide you along your journey to succeeding in this fascinating
              market!
            </p> */}
            <PopupButton
              className="btnClass"
              url="https://calendly.com/attorneybitcoin"
              rootElement={document.getElementById("root")}
              text="Book A Call!"
            />
          </div>
          {/* Div 2 */}
          <div className="w-full">
            <img src="/img/manWaking.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MeetAttorneySection;
