function BrandsSection() {
    return (
        <section className="sectionPy md:my-10">
            <div className="container_lg">
                {/*Logo Grid */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-20">
                    <div className="flex items-center justify-center gap-5 contrast-0 hover:contrast-100 duration-150">
                        <img src="/img/logo_gray.png" alt="" />
                    </div>
                    <div className="flex items-center justify-center gap-5 contrast-0 hover:contrast-100 duration-150">
                        <img src="/img/logo_gray.png" alt="" />
                    </div>
                    <div className="flex items-center justify-center gap-5 contrast-0 hover:contrast-100 duration-150">
                        <img src="/img/logo_gray.png" alt="" />
                    </div>
                    <div className="flex items-center justify-center gap-5 contrast-0 hover:contrast-100 duration-150">
                        <img src="/img/logo_gray.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandsSection
