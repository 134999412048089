import { useEffect, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
// import { format, parseISO } from 'date-fns'
import moment from "moment";
import { Pagination } from "swiper";

function NewsSection() {
  const [newsData, setNewsData] = useState(null);
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    const getNews = () => {
      setStatus("loading");
      fetch(
        `https://cryptonews-api.com/api/v1?tickers=BTC&items=20&token=8cnhgfylfxaaibxnq3ketwp6lmmufguzpdkqk2j9`
      )
        .then((res) => res.json())
        .then((data) => {
          setNewsData(data?.data || null);
          setStatus("idle");
        })
        .catch((err) => setStatus("error"));
    };

    getNews();
  }, []);

  console.log({ newsData, status });

  return (
    <section
      className="md:sectionPy md:mb-14 scroll-mt-28 md:scroll-mt-10"
      id="news"
    >
      <div className="container_lg">
        <h3 className="titleClass text-center">The News You Should Know</h3>

        {/* Grid */}
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-16">

                    

                </div> */}

        <div className="mt-16 relative flex flex-col-reverse">
          {/* News Loading Status --Start-- */}
          {status !== "idle" && (
            <div className="flex items-center justify-center h-32 bg-[#F9F9F9] text-xl font-semibold text-slate-900">
              {status === "loading"
                ? "Loading.."
                : "Failed to load news data :("}
            </div>
          )}
          {/* News Loading Status --End-- */}

          {/* Pagination --Start-- */}
          <div
            id="news_pagination"
            className="flex justify-center flex-wrap w-full mt-7 gap-2 gap-y-3"
          ></div>
          {/* Pagination --End-- */}
          {newsData && (
            <div>
              <Swiper
                slidesPerView={2}
                spaceBetween={40}
                modules={[Pagination]}
                pagination={{
                  el: "#news_pagination",
                  clickable: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1.1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                }}
              >
                {newsData.map((news, i) => (
                  <SwiperSlide key={i}>
                    <div className="bg-[#1C1E29]">
                      {/* Image Div */}
                      <div className="border-b border-[#707070]">
                        <img src={news?.image_url} alt={news?.title} />
                      </div>
                      {/* Text Info Div */}
                      <div className="bg-[#1C1E29] text-soft-white p-8 pb-10 space-y-5">
                        <span className="font-openSans font-semibold text-[18px]">
                          {/* FEB 27, 2022 */}
                          {moment(news.date).format("MMMM DD, YYYY")}
                        </span>
                        <h5 className="titleClass text-soft-white text-xl line-clamp-1">
                          <a
                            href={news?.news_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {news?.title}
                          </a>
                        </h5>
                        <p className="paraClass text-soft-white line-clamp-2">
                          {news?.text}
                        </p>
                        {/*Article Butoon Div */}
                        <div>
                          <a
                            href={news?.news_url}
                            target="_blank"
                            rel="noreferrer"
                            className="uppercase text-[18px] text-soft-white hover:text-white duration-150 group font-semibold font-openSans inline-flex gap-8 items-center"
                          >
                            <span>Read More</span>
                            <div className="group-hover:translate-x-3 duration-150">
                              <HiArrowNarrowRight size={22} />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default NewsSection;
