function PriceSection() {
    return (
        <section className="md:sectionPy !pb-0">
            <div className="container_lg">
                <div className="space-y-5">
                    <h3 className="titleClass text-center">
                        Secure Consultancy Pricing
                    </h3>
                    <p className="paraClass text-center text-base md:text-lg font-semibold text-[#1C1E29]">
                        The fees listed are for 1 hours secure consultancy.
                    </p>
                </div>
                {/* Down Side */}
                <div className="mt-14 space-y-7">
                    {/* Line Number One */}
                    <div className="flex flex-col md:flex-row justify-center gap-4 md:justify-around items-center bg-soft-white py-10">
                        <div className="flex flex-col md:flex-row gap-5 items-center">
                            <img src="/img/btc_icon.png" alt="btc" />
                            <span className="uppercase font-semibold text-2xl font-openSans">
                                btc
                            </span>
                        </div>
                        <div className="text-base font-openSans fnt-semibold">
                            3NPipntm6Vne5cxRkKS1hLwXdmFFKVmVjc{" "}
                        </div>
                        <div className="flex gap-5">
                            <span className="text-[#8A8A8A]">Fee</span>
                            <span>0.00125 BTC</span>
                        </div>
                    </div>
                    {/* Line Number Two */}
                    <div className="flex flex-col md:flex-row justify-center gap-4 md:justify-around items-center bg-soft-white py-10">
                        <div className="flex flex-col md:flex-row gap-5 items-center">
                            <img src="/img/Group 341.png" alt="eth" />
                            <span className="uppercase font-semibold text-2xl font-openSans">
                                eth
                            </span>
                        </div>
                        <div className="text-base font-openSans fnt-semibold">
                            3NPipntm6Vne5cxRkKS1hLwXdmFFKVmVjc{" "}
                        </div>
                        <div className="flex gap-5">
                            <span className="text-[#8A8A8A]">Fee</span>
                            <span>0.00125 BTC</span>
                        </div>
                    </div>
                    {/* Line Number three */}
                    <div className="flex flex-col md:flex-row justify-center gap-4 md:justify-around items-center bg-soft-white py-10">
                        <div className="flex flex-col md:flex-row gap-5 items-center">
                            <img src="/img/sol.png" alt="sol" />
                            <span className="uppercase font-semibold text-2xl font-openSans">
                                sol
                            </span>
                        </div>
                        <div className="text-base font-openSans fnt-semibold">
                            3NPipntm6Vne5cxRkKS1hLwXdmFFKVmVjc{" "}
                        </div>
                        <div className="flex gap-5">
                            <span className="text-[#8A8A8A]">Fee</span>
                            <span>0.00125 BTC</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PriceSection
