import { BsTelephoneFill } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";

import HeroSection from "./components/HeroSection";
import MeetAttorneySection from "./components/MeetAttorneySection";
import NewsSection from "./components/NewsSection";
// import GuideSection from "./components/GuideSection";
import PriceSection from "./components/PriceSection";
import BrandsSection from "./components/BrandsSection";
import NewToBitcoinSection from "./components/NewToBitcoinSection";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <MeetAttorneySection />
      <NewsSection />
      {/* <GuideSection /> */}
      <PriceSection />
      <BrandsSection />
      <NewToBitcoinSection />
      <Footer />
    </>
  );
}
