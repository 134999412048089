import { VscArrowDown } from "react-icons/vsc";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer";
import { PopupButton } from "react-calendly";

function HeroSection() {
  return (
    <section
      className="h-[110vh] md:h-screen bg-cover bg-center bg-no-repeat relative"
      style={{
        backgroundImage: "url('/img/Headerbg.png')",
      }}
      id="home"
    >
      <div className="container_lg h-full">
        <div className="h-full flex flex-col justify-center items-center gap-14 md:gap-0 md:grid md:grid-cols-[50%,50%] md:place-items-center">
          <div>
            <h1 className="titleClass text-4xl md:text-6xl md:leading-[1.3] font-bold text-white mb-8">
              Online Cryptocurrency Consultation with Attorney Greenberg
            </h1>
            <p className="paraClass text-soft-white md:w-[82%] font-semibold mb-8 md:mb-12 leading-[1.7]">
              Bitcoin is the future. It holds great power allowing hundreds of
              thousands of transactions, creating new millionaires every single
              day. Your understanding is the next key to success.
            </p>
            <div className="flex flex-col sm:flex-row md:items-center md:justify-start gap-4 md:gap-6">
              <PopupButton
                className="btnClass"
                url="https://calendly.com/attorneybitcoin"
                rootElement={document.getElementById("root")}
                text="Book A Call!"
              />
              <button className="btnClass bg-[#4A4C59] hover:bg-[#40424e]">
                our tutorial
              </button>
            </div>
          </div>

          <div>
            <VideoPlayer />
          </div>
        </div>
      </div>

      {/* Social */}
      <div className="absolute bottom-10 left-0 w-full z-10">
        <div className="container_lg flex items-center justify-between">
          <div>
            <a href="#meet-attorney">
              <VscArrowDown size={30} color={"#EAAB1C"} />
            </a>
          </div>
          <div className="flex gap-6 text-white">
            <a className="hover:text-primary duration-100" href="#">
              <FaFacebookF size={28} />
            </a>
            <a className="hover:text-primary duration-100" href="#">
              <FaInstagram size={28} />
            </a>
            <a className="hover:text-primary duration-100" href="#">
              <FaTwitter size={28} />
            </a>
            <a className="hover:text-primary duration-100" href="#">
              <FaLinkedin size={28} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
